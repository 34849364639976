import {
  ArrowBack,
  ArrowForward,
  Close,
  WarningOutlined,
} from "@mui/icons-material";
import {
  Box,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";

const Layout = ({
  onClose,
  leftContent,
  rightContent,
  duplicatedNum = 0,
  duplicatedIndex,
  onNext,
  onPrev,
}) => {
  return (
    <Box display={"flex"} flex={1} flexBasis={0}>
      <Box
        bgcolor={(t) => `${t.palette.warning.main}10`}
        flex={1}
        boxShadow={3}
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
      >
        <DialogTitle boxShadow={1}>
          <IconButton sx={{ m: -1, mr: 1 }} onClick={onClose}>
            <Close />
          </IconButton>
          <WarningOutlined color="warning" sx={{ mr: 2 }} />
          Risco de duplicação <Box flex={1} />
        </DialogTitle>
        <DialogContent
          sx={{
            borderRight: 1,
            flex: 1,
            flexBasis: 0,
            borderColor: "divider",
          }}
        >
          {leftContent}
        </DialogContent>
      </Box>
      <Box flex={0.8} height={"100%"} display={"flex"} flexDirection={"column"}>
        <DialogTitle>
          {duplicatedNum === 1
            ? "Semelhante com a despesa..."
            : `Semelhante com ${duplicatedNum} despesas`}

          <Box flex={1} />
          {duplicatedNum > 1 && (
            <>
              <Typography fontWeight={"500"} mr={2}>
                {duplicatedIndex + 1}/{duplicatedNum}
              </Typography>
              <IconButton
                disabled={duplicatedIndex === 0}
                sx={{ mr: 1 }}
                onClick={onPrev}
                size="small"
              >
                <ArrowBack />
              </IconButton>
              <IconButton
                disabled={duplicatedIndex + 1 === duplicatedNum}
                onClick={onNext}
                size="small"
              >
                <ArrowForward />
              </IconButton>
            </>
          )}
        </DialogTitle>
        <Divider />
        <DialogContent
          sx={{
            flex: 1,
            flexBasis: 0,
            borderRight: 1,
            borderColor: "divider",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {rightContent}
        </DialogContent>
      </Box>
    </Box>
  );
};

export default Layout;
