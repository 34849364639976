import { LiquorRounded } from "@mui/icons-material";
import { Box, Chip, Typography } from "@mui/material";
import React, { memo } from "react";

const TagBox = memo(({ label = "", Icon }) => {
  return (
    <Chip
      variant="outlined"
      color="warning"
      icon={<Icon />}
      size="small"
      label={
        <Typography ml={0.5} color={"text.primary"} variant="inherit">
          {label}
        </Typography>
      }
      sx={{
        fontWeight: "600",
        px: 1,
        bgcolor: "background.default",
      }}
    />
  );
});

const TagsIndicator = ({ tags = [] }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      py={0.3}
      px={1.5}
      gap={1}
    >
      {tags?.includes("alcohol") && (
        <TagBox Icon={LiquorRounded} label="Bebida alcoólica" />
      )}
    </Box>
  );
};

export default memo(TagsIndicator);
