import { store } from "../../store/store";

export const getReceiptUrl = (url, h = undefined, w = undefined) => {
  if (!url) return null;
  const receiptsToken = store.getState().account.receiptsAccessToken?.token;
  return (
    url +
    `&token=${receiptsToken}` +
    `${h ? `&h=${h}` : ""}` +
    `${w ? `&w=${w}` : ""}`
  );
};
